<template>
  <div id="app">
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    initCNZZ() {
      //添加脚本
      const script = document.createElement("script");
      script.src =
        "https://s4.cnzz.com/z_stat.php?id=1280084073&web_id=1280084073";
      script.language = "JavaScript";
      script.id = "cnzz";
      document.body.appendChild(script);
    },
  },
  mounted() {
    if (this._isMobile()) {

      (function flexible(window, document) {
        var docEl = document.documentElement;
        var dpr = window.devicePixelRatio || 1;

        // adjust body font size
        function setBodyFontSize() {
          if (document.body) {
            document.body.style.fontSize = 12 * dpr + "px";
          } else {
            document.addEventListener("DOMContentLoaded", setBodyFontSize);
          }
        }
        setBodyFontSize();

        // set 1rem = viewWidth / 10
        function setRemUnit() {
          var rem = docEl.clientWidth / 10;
          docEl.style.fontSize = rem + "px";
        }

        setRemUnit();

        // reset rem unit on page resize
        window.addEventListener("resize", setRemUnit);
        window.addEventListener("pageshow", function (e) {
          if (e.persisted) {
            setRemUnit();
          }
        });

        // detect 0.5px supports
        if (dpr >= 2) {
          var fakeBody = document.createElement("body");
          var testElement = document.createElement("div");
          testElement.style.border = ".5px solid transparent";
          fakeBody.appendChild(testElement);
          docEl.appendChild(fakeBody);
          if (testElement.offsetHeight === 1) {
            docEl.classList.add("hairlines");
          }
          docEl.removeChild(fakeBody);
        }
      })(window, document);
    }

    this.initCNZZ();
  },
  watch: {
    $route() {
      
      if (window._czc) {
        //监听路由变化
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
        window._czc.push(["_setAutoPageview", false]);
      }
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: "思源宋体", "MicrosoftYaHei", arial, "Franklin Gothic Medium",
    "Arial Narrow", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 11px;
  border: none;
  background: #ddd !important;
  opacity: 0.5;
}

::-webkit-scrollbar-track-piece {
  border: none;
  position: absolute;
  padding: 0;
  box-shadow: none;
  background-color: #fff;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #001529;
  border-radius: 6px;
  border: none;
  opacity: 0.1;
}

::-webkit-scrollbar-button:vertical {
  display: none;
}

::-webkit-scrollbar-track:vertical {
  background-color: black;
}

::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #bebebe;
}

::-webkit-scrollbar-corner:vertical {
  background-color: #535353;
}

::-webkit-scrollbar-resizer:vertical {
  background-color: #001529;
}
</style>
