import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const redirectPath = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? '/mindex' : '/index'
    // const redirectPath = "/index"
const routes = [{
        path: '/',
        redirect: redirectPath,
    },
    // Pc端路由
    {
        path: '/index',
        name: 'PCIndex',
        meta: { type: 'pc' },
        component: () =>
            import ('@/views/PC/Index.vue')
    },
    {
        path: '/product',
        name: 'PCProduct',
        meta: { type: 'pc' },
        component: () =>
            import ('@/views/PC/Product.vue'),
        redirect: "/product/list/rangcha",
        children: [{
                path: '/product/list/rangcha',
                name: 'PCRangchaList',
                meta: { type: 'pc' },
                component: () =>
                    import ('@/views/PC/RangchaList.vue'),
            },
            {
                path: '/product/list/tasantea',
                name: 'PCTasnateaList',
                meta: { type: 'pc' },
                component: () =>
                    import ('@/views/PC/TasnateaList.vue'),
            }, {
                path: '/product/rangcha/detail',
                name: 'PCRangchaProductDetail',
                meta: { type: 'pc' },
                component: () =>
                    import ('@/views/PC/RangchaProductDetail.vue'),
            },
            {
                path: '/product/tasantea/detail',
                name: 'PCTasanteaProductDetail',
                meta: { type: 'pc' },
                component: () =>
                    import ('@/views/PC/TasanteaProductDetail.vue'),
            },

        ]
    },
    {
        path: '/company',
        name: 'PCCompany',
        meta: { type: 'pc' },
        component: () =>
            import ('@/views/PC/Company.vue')
    },
    {
        path: '/news',
        name: 'PCNews',
        meta: { type: 'pc' },
        component: () =>
            import ('@/views/PC/News.vue')
    },
    {
        path: '/pc/test',
        name: 'Test',
        meta: { type: 'pc' },
        component: () =>
            import ('@/views/PC/Test.vue'),
    },
    // 移动端路由
    {
        path: '/mindex',
        name: 'MobileIndex',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/Index.vue')
    },
    {
        path: '/mrangcha/list',
        name: 'MobileRangchaProductList',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/RangchaProductList.vue')
    },
    {
        path: '/mrangcha/detail',
        name: 'MobileRangchaProductDetail',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/RangchaProductDetail.vue')
    },
    {
        path: '/mtasantea/list',
        name: 'MobileTasanteaProductList',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/TasanteaProductList.vue')
    },
    {
        path: '/mtasantea/detail',
        name: 'MobileTasanteaProductDetail',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/TasanteaProductDetail.vue')
    },
    {
        path: '/mcompany',
        name: 'MobileCompanyIntroduce',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/CompanyIntroduce.vue')
    },
    {
        path: '/mnews',
        name: 'MobileCompanyNews',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/CompanyNews.vue')
    },
    {
        path: '/mtest',
        name: 'MobileTest',
        meta: { type: 'mobile' },
        component: () =>
            import ('@/views/Mobile/MTest.vue')
    },

]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
        // 阻止PC端路由跳转移动端路由
        if (to.meta.type == "mobile" && redirectPath == "/index") {
            next("/index")
        } else {
            next()
        }

        // 阻止移动端端路由跳转PC端路由
        if (to.meta.type == "pc" && redirectPath == "/mindex") {
            next("/mindex")
        } else {
            next()
        }
    })
    // 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}


export default router



// 6f5e1060645af23b066ced45a0ee351a  gists  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