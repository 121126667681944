<template>
  <div id="Header">
    <div class="nav_box">
      <div class="nav_logo" @click="toIndex">
        <img src="../assets/index/logo.jpg" alt="" srcset="" />
      </div>
     <!--  <span style="font-family:'Alibaba95'">专注无糖茶饮料</span>
      <span style="padding:0 12px">|</span>
      <span style="font-family:'Alibaba45'">让茶陪你每一天 </span> -->
      <div class="nav_right_box">
        <div
          :class="[active_index == 0 ? 'nav_item_active' : 'nav_item']"
          @click="nav_click(0)"
        >
          首页
        </div>
        <div
          :class="[active_index == 1 ? 'nav_item_active' : 'nav_item']"
          @click="nav_click(1)"
        >
          企业品牌
        </div>
        <div :class="[active_index == 2 ? 'nav_item_active' : 'nav_item']">
          关于我们
          <i style="padding-left: 5px" class="el-icon-arrow-down"></i>
          <div class="select_box">
            <div class="select_item" @click="nav_click(3)">企业介绍</div>
            <div class="select_item" @click="nav_click(4)">企业新闻</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  props: {},
  setup() {},
  data() {
    return {
      active_index: 0,
      list: ["首页", "企业品牌", "关于我们"],
      select_style: "",
    };
  },
  methods: {
    toIndex() {
      sessionStorage.setItem("active_index", 0);
      this.$router.push("/index");
    },
    nav_click(index) {
      if (index == 3 || index == 4) {
        this.active_index = 2;
      } else {
        this.active_index = index;
      }
      sessionStorage.setItem("active_index", this.active_index);

      if (index == 0) {
        this.$router.push("/index");
      } else if (index == 1) {
        /* this.$router.push("/product"); */
        this.$router.push("/product/list/rangcha?h=1");
      } else if (index == 2) {
        this.select_style = "height:80px;";
      } else if (index == 3) {
        this.$router.push("/company");
      } else {
        this.$router.push("/news");
      }
    },
  },
  created() {
    if (sessionStorage.getItem("active_index")) {
      this.active_index = sessionStorage.getItem("active_index");
    } else {
      this.active_index = 0;
    }
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#Header {
  width: 100%;
  height: 64px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background: #fff;
  @width: 62.5%;
  @min_width: 996px;
  .nav_box {
    /* width: 1200px; */
    width: @width;
    min-width: @min_width;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    line-height: 64px;
    .nav_logo {
     /*  width: 158px; */
      height: 64px;
      float: left;
      /* margin-top: 17px; */
      cursor: pointer;
      img{
        /* vertical-align: middle; */
        height: 64px;
      }
    }
    .nav_right_box {
      height: 100%;
      float: right;
      .nav_item {
        cursor: pointer;
       /*  margin-top: 17px; */
        float: left;
        margin-right: 28px;
        position: relative;
      }
      .nav_item_active {
        cursor: pointer;
        /* margin-top: 17px; */
        float: left;
        margin-right: 28px;
        position: relative;
        color: #45B035;
      }
      .nav_item,
      .nav_item_active {
        .select_box {
          width: 120px;
          height: 0;
          background: #fff;
          position: absolute;
          top: 48px;
          left: -10px;
          z-index: 997;
          transition: all 0.5s;
          overflow: hidden;
          .select_item {
            width: 100%;
            height: 40px;
            /* text-align: center; */
            padding-left: 15px;
            box-sizing: border-box;
            line-height: 40px;
            color: #121212;
            &:hover {
              background: #45B035;
              color: #fff;
            }
          }
        }
        &:hover .select_box {
          height: 80px;
        }
      }
    }
  }
}
</style>
