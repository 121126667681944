import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '../theme/index.css'

import "./assets/iconfont/iconfont.css"

import "./assets/font/font.less"
import {
    Button,
    Menu,
    Submenu,
    MenuItem,
    Row,
    Col,
    Pagination,
    Form,
    FormItem,
    Input,
} from 'element-ui';

import { Tabs, Tab, Icon, List } from 'vant';


Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)

Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Icon)
Vue.use(List)



Vue.config.productionTip = false


/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */
router.beforeEach((to, from, next) => {
    if (to.fullPath == "/") {
        if (navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )) {
            next("/mindex");
        } else {
            next();
        }
    } else {
        next();
    }
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')